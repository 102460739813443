import "./App.css";

import eudagif from "./images/eudagif.gif";
import moment from "moment";

// 12:07 pm PT
const birthday = moment("2022-10-08T19:07:00.000Z");
const now = moment();
const dur = moment.duration(now.diff(birthday));

function App() {
  console.log(dur);

  return (
    <div className="App">
      <div className="timer">
        <div>{dur.years()} years</div>
        <div>{dur.months()} months</div>
        <div>{dur.days()} days</div>
      </div>
      <img
        src={eudagif}
        alt="Cute baby squirming wearing 'TINY BOSS' shirt"
        className="euda"
      />
    </div>
  );
}

export default App;
